import { Box, BoxProps } from "@biblioteksentralen/js-utils";
import { DecorativeImage, ImageWithMetadata } from "@libry-content/types";
import NextImage from "next/image";
import { safelyGetImageDimensions } from "./safelyGetImageDimensions";
import { imageUrlBuilder } from "@libry-content/common";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { isImageWithMetadata } from "./SanityImage";

type Props = { image?: DecorativeImage | ImageWithMetadata; resolution: number; alt?: string } & BoxProps;

export function SanityImagePreservingAspectRatio({
  image,
  resolution,
  boxShadow = "md",
  alt = "",
  ...chakraProps
}: Props) {
  const { t, ts } = useTranslation();
  if (!image?.asset) return null;

  const src = imageUrlBuilder(image)?.width(resolution).format("webp").quality(80).url() ?? "";
  const blurImageUrl = imageUrlBuilder(image)?.width(10).format("webp").quality(70).url();
  const dimensions = safelyGetImageDimensions(image);
  const altText = isImageWithMetadata(image) && image.altText?.enabled ? ts(image.altText?.text) ?? alt : alt;

  return (
    <>
      <Box
        lineHeight={0}
        borderRadius="0.5em"
        boxShadow={boxShadow}
        position="relative"
        overflow="hidden"
        {...chakraProps}
      >
        <NextImage
          placeholder="blur"
          blurDataURL={blurImageUrl}
          src={src}
          alt={altText}
          height={Math.round(resolution / dimensions.aspectRatio)}
          width={resolution}
        />
      </Box>
      {isImageWithMetadata(image) && image.creator && (
        // Use absolute positioned box; a wrapper around component breaks book list preview layout
        <Box
          fontStyle="italic"
          marginTop="0.1rem"
          fontSize="sm"
          opacity={0.8}
          position="absolute"
          top="100%"
          left="50%;"
          transform="translateX(-50%)"
          whiteSpace="nowrap"
        >
          {t("Foto: {opphavsPerson}", { opphavsPerson: image.creator })}
        </Box>
      )}
    </>
  );
}
